<!-- tabBar -->
<template>
  <div class="container">
    <!-- <TopWrapper
      :topObj="{
        bgColor: '#fff',
        title: '打卡',
        txtColor: '#333',
        isBack: true,
        isMsg: false,
      }"
    /> -->

    <router-view></router-view>
    <div class="footer-wrapper">
      <van-tabbar v-model="activeBar" active-color="#0094ee">
        <van-tabbar-item name="clockIn" @click="jump('/clockIn')" icon="chat-o"
          >打卡</van-tabbar-item
        >
        <van-tabbar-item
          name="statistics"
          icon="cashier-o"
          @click="jump('/clockIn/statistics')"
        >
          统计
        </van-tabbar-item>
      </van-tabbar>
    </div>
  </div>
</template>

<script>
import TopWrapper from "@/components/topWrapper/index.vue";
export default {
  data() {
    return {
      activeBar: "",
    };
  },

  props: {},
  watch: {},

  components: {TopWrapper},

  computed: {},

  mounted() {
    let routeLeaf = window.location.href.replace(/.*\//, "");
    this.activeBar = routeLeaf;
  },

  methods: {
    jump(path) {
      this.$router.push(path);
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  height: 100%;
}
::v-deep .van-tabbar-item {
  .van-info {
    white-space: nowrap;
  }
}
</style>
